<template>
  <div
    class="listproject__wrapper"
    data-aos="fade-up"
    data-aos-duration="500"
    data-aos-offset="300"
  >
    <div class="listproject__header">
      <h2 class="text-center" style="max-width: 752px">{{ header.title }}</h2>
      <p class="text-center" style="max-width: 552px; width: 80%">
        {{ header.subtitle }}
      </p>
    </div>
    <div class="listproject col-11 col-sm-10 m-auto">
      <div class="slider__container d-flex">
        <div class="slider__description">
          <div
            v-for="(tab, index) in [
              cards[cards.length - 1],
              ...cards.slice(0, -1),
            ]"
            :key="index"
            :class="[
              model === tab.category.replace(' ', '')
                ? 'active'
                : 'slider__control',
            ]"
          >
            <button
              @click="changeTab(tab.category.replace(' ', ''))"
              class="slider__control__content-category"
            >
              {{ tab.category }}
            </button>
          </div>
        </div>
        <div
          class="slider__block grid"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="300"
          :key="key"
        >
          <div
            v-for="(project, indexProject) in currentTab"
            :key="indexProject"
            class="listprojects__item"
          >
            <router-link :to="`/projects/${project.id}`">
              <div class="overflow-hidden">
                <div
                  class="listprojects__item--image"
                  :style="{ backgroundImage: `url(${project.image[0]})` }"
                ></div>
              </div>
              <div class="listprojects__item--content">
                <div>
                  <h5 class="mb-1">{{ project.title }}</h5>
                </div>
                <nav class="">{{ project.tech }}</nav>
                <p>
                  {{
                    project.content[1].content.split('\n')[0].split(' ')
                      .length > 20
                      ? project.content[1].content
                          .split('\n')[0]
                          .split(' ')
                          .slice(0, 20)
                          .join(' ') + '...'
                      : project.content[1].content.split('\n')[0].indexOf(' ') >
                        0
                      ? project.content[1].content.split('\n')[0]
                      : project.content[1].content.split('\n')[0].slice(0, 50) +
                        '...'
                  }}
                </p>
                <button class="btn-viewmore">
                  {{ button.seeMore }}
                  <img :src="imagePath" alt="Button View More Project" />
                </button>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ListProject',
  data() {
    return {
      model: 'All',
      project: this.$t('project_detail'),
      projectAll: this.$t('home').project,
      service: this.$t('home').service,
      cards: this.$t('home').service.cards,
      button: this.$t('button'),
      header: {
        title: '',
        subtitle: '',
      },
      categories: {},
      currentTab: [],
      seo: this.$t('seo').product,
      imagePath: '/images/projects/ButtonViewProject.png',
      key: 0,
    };
  },
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [
        {
          name: 'description',
          content: this.seo.description,
        },
        {
          property: 'og:title',
          content: this.seo.title,
        },
        {
          property: 'og:description',
          content: this.seo.description,
        },
      ],
    };
  },
  components: {},
  methods: {
    changeTab(model) {
      this.model = model;
      this.currentTab = this.categories[model];
      this.getTitle(model);
      if (model === 'All') {
        this.$router.replace({
          path: this.$route.path,
          query: null,
        });
      } else {
        const newQuery = { category: model };
        this.$router.replace({
          path: this.$route.path,
          query: newQuery,
        });
      }
      this.key += 1;
      this.scrollToTop();
    },
    getTitle(category) {
      for (let item of this.cards) {
        if (category === item.category.replace(' ', '')) {
          this.header = {
            title: item.title,
            subtitle: item.content,
          };
          break;
        }
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    let listCategory;
    let arrCategory = this.project.listProjectDetails.map((item) => {
      return item.category;
    });
    listCategory = arrCategory.filter(function (item, pos) {
      return arrCategory.indexOf(item) === pos;
    });
    this.categories = Object.assign(
      { All: this.project.listProjectDetails },
      ...listCategory.map((category) => {
        return {
          [category.replace(' ', '')]: this.project.listProjectDetails.filter(
            (item) => {
              return (
                item.category.replace(' ', '') === category.replace(' ', '')
              );
            }
          ),
        };
      })
    );
    this.model = this.$route.query.category || 'All';
    this.getTitle(this.model);
    // if (
    //   !Object.keys(this.categories).includes(this.$route.query.category) &&
    //   this.model !== 'All'
    // ) {
    //   console.log('jjh');
    //   // history.back();
    //   this.$router.replace({
    //     query: {},
    //   });
    // }
    this.currentTab = this.categories[this.model];
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
  watch: {
    lang() {
      if (this.model !== 'All') {
        this.$router.replace({
          path: this.$route.path,
          query: null,
        });
      }
      this.seo = this.$t('seo').product;
      this.project = this.$t('project_detail');
      this.service = this.$t('home').service;
      this.projectAll = this.$t('home').project;
      this.button = this.$t('button');
      this.cards = this.$t('home').service.cards;
      let listCategory;
      let arrCategory = this.project.listProjectDetails.map((item) => {
        return item.category;
      });
      listCategory = arrCategory.filter(function (item, pos) {
        return arrCategory.indexOf(item) === pos;
      });
      this.categories = Object.assign(
        { All: this.project.listProjectDetails },
        ...listCategory.map((category) => {
          return {
            [category.replace(' ', '')]: this.project.listProjectDetails.filter(
              (item) => {
                return (
                  item.category.replace(' ', '') === category.replace(' ', '')
                );
              }
            ),
          };
        })
      );
      this.model = this.$route.query.category || 'All';
      this.getTitle(this.model);
      this.currentTab = this.categories[this.model];
    },
    // model() {
    //   this.currentTab = this.categories[this.model];
    // }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>

<style lang="scss" scroped>
.listproject__header {
  margin-top: 38px;
  padding-top: 100px;
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url('/images/bg/bg-about.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 10%;
  h2 {
    position: relative;
    font-size: 40px;
    font-weight: 700;
    color: var(--blue-text);
    max-width: 800px;
    z-index: 0;
    margin-bottom: 20px;
    text-transform: none;
    &::first-letter {
      text-transform: uppercase;
    }
    &::after {
      content: '';
      position: absolute;
      background: url('/images/bg/circle-title.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 38px;
      height: 38px;
      z-index: -1;
      top: 0;
      left: -18px;
    }
  }
  p {
    color: var(--grey-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    max-width: 650px;
    white-space: pre-line;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    height: 100%;
    h2 {
      font-size: 32px;
    }

    p {
      width: 70% !important;
    }
  }
}
.slider__block.grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 27px;
  margin-top: -10px;
}
.listproject {
  margin: 0 auto;
  padding: 20px 0 50px;
  .slider__container {
    flex-direction: row;
    gap: 135px;
  }
  .slider__description {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 117px;
  }
  .slider__control .slider__control__content-category {
    background: #fff;
    padding: 14px 20px;
    text-align: left;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
    border: none;
    transition: all 0.2s linear;
    width: 100%;
    &:hover {
      background-color: #ff9d6c25;
    }
  }
  .active .slider__control__content-category {
    background: #fc7735;
    height: 57px;
    width: 100%;
    color: #fff;
    padding: 14px 20px;
    text-align: left;
    border-radius: 10px;
    font-weight: 500;
    border: none;
    font-size: 18px;
  }

  .btn-viewmore {
    margin-left: auto;
    color: #ff7735;
    font-size: 16px;
    font-weight: 600;
    border-radius: 225px;
    background: none;
    border: none;
    img {
      padding-left: 10px;
    }
  }

  .listprojects__item {
    display: block;
    height: 455px;
    text-decoration: none;
    width: 100%;
    box-shadow: -1px 2px 7px -1px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    overflow: hidden;
    &:hover {
      .listprojects__item--image {
        transition: all 0.3s;
        transform: scale(1.1);
      }
    }
    .listprojects__item--image {
      width: 100%;
      height: 215px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
    }
    .listprojects__item--content {
      width: 100%;
      height: 240px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #ffffff;
      box-shadow: -1px 2px 7px -1px rgba(0, 0, 0, 0.25);
      p,
      nav,
      h5 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        white-space: pre-line;
        font-size: 16px;
        font-weight: 400;
        color: #595959;
        line-height: 1.5;
        min-height: 4.5em;
        -webkit-line-clamp: 3;
      }
      h5 {
        font-weight: 700;
        font-size: 20px;
        color: #373737;
        padding: 0;
        -webkit-line-clamp: 2;
        line-height: 1.5;
        min-height: 3em;
      }
      nav {
        -webkit-line-clamp: 2;
        font-size: 13px;
        font-weight: 400;
        color: #fc7735;
        padding-bottom: 5px;
        line-height: 1.5;
        min-height: 3em;
      }
    }
  }

  .listproject__container {
    width: 80%;
    margin: 0 auto;
  }
  .listproject__content {
    position: relative;
    font-weight: 700;
    font-size: 40px;
    color: var(--blue-text);
    max-width: 800px;
    z-index: 0;
    margin-bottom: 52px;
    &::after {
      content: '';
      position: absolute;
      background-color: var(--orange-background);
      border-radius: 50%;
      width: 38px;
      height: 38px;
      z-index: -1;
      top: 0;
      left: -18px;
    }
    span {
      color: var(--blue-text);
    }
  }
  .listproject__block {
    display: flex;
    gap: 36px;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .listproject__block__item {
    padding: 50px 100px;
    text-align: center;
    border-radius: 25px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    p {
      margin: 0;
      margin-top: 24px;
      font-weight: 700;
      font-size: 18px;
      color: var(--blue-text);
      text-transform: uppercase;
    }
  }

  @media (max-width: 1560px) {
    .slider__container {
      gap: 40px;
    }

    .slider__description {
      margin-left: 40px;
    }
  }

  @media (max-width: 1380px) {
    .listproject__block__item {
      padding: 30px 60px;
    }
    .slider__block.grid {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 20px;
    }

    .slider__description {
      margin-left: 30px;
    }

    .slider__container {
      gap: 30px;
    }
  }
  @media (max-width: 1024px) {
    .slider__container {
      flex-direction: column;
      gap: 50px;
    }
    .slider__block.grid {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 20px;
    }

    .slider__description {
      margin: 0;
      text-align: center;
    }

    .slider__control .slider__control__content-category {
      width: 294px;
      text-align: center;
    }

    .active .slider__control__content-category {
      width: 294px;
      text-align: center;
    }
  }
  @media (max-width: 600px) {
    .slider__container {
      gap: 20px;
      flex-direction: column;
    }

    .slider__description {
      margin-bottom: 30px;
    }

    .slider__control .slider__control__content-category {
      width: 294px;
      text-align: center;
    }

    .active .slider__control__content-category {
      width: 294px;
      text-align: center;
    }
  }
}
</style>
import { includes } from 'core-js/core/array';
