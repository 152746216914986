var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listproject__wrapper",attrs:{"data-aos":"fade-up","data-aos-duration":"500","data-aos-offset":"300"}},[_c('div',{staticClass:"listproject__header"},[_c('h2',{staticClass:"text-center",staticStyle:{"max-width":"752px"}},[_vm._v(_vm._s(_vm.header.title))]),_c('p',{staticClass:"text-center",staticStyle:{"max-width":"552px","width":"80%"}},[_vm._v(" "+_vm._s(_vm.header.subtitle)+" ")])]),_c('div',{staticClass:"listproject col-11 col-sm-10 m-auto"},[_c('div',{staticClass:"slider__container d-flex"},[_c('div',{staticClass:"slider__description"},_vm._l(([
            _vm.cards[_vm.cards.length - 1],
            ..._vm.cards.slice(0, -1),
          ]),function(tab,index){return _c('div',{key:index,class:[
            _vm.model === tab.category.replace(' ', '')
              ? 'active'
              : 'slider__control',
          ]},[_c('button',{staticClass:"slider__control__content-category",on:{"click":function($event){_vm.changeTab(tab.category.replace(' ', ''))}}},[_vm._v(" "+_vm._s(tab.category)+" ")])])}),0),_c('div',{key:_vm.key,staticClass:"slider__block grid",attrs:{"data-aos":"fade-up","data-aos-duration":"500","data-aos-offset":"300"}},_vm._l((_vm.currentTab),function(project,indexProject){return _c('div',{key:indexProject,staticClass:"listprojects__item"},[_c('router-link',{attrs:{"to":`/projects/${project.id}`}},[_c('div',{staticClass:"overflow-hidden"},[_c('div',{staticClass:"listprojects__item--image",style:({ backgroundImage: `url(${project.image[0]})` })})]),_c('div',{staticClass:"listprojects__item--content"},[_c('div',[_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(project.title))])]),_c('nav',{},[_vm._v(_vm._s(project.tech))]),_c('p',[_vm._v(" "+_vm._s(project.content[1].content.split('\n')[0].split(' ') .length > 20 ? project.content[1].content .split('\n')[0] .split(' ') .slice(0, 20) .join(' ') + '...' : project.content[1].content.split('\n')[0].indexOf(' ') > 0 ? project.content[1].content.split('\n')[0] : project.content[1].content.split('\n')[0].slice(0, 50) + '...')+" ")]),_c('button',{staticClass:"btn-viewmore"},[_vm._v(" "+_vm._s(_vm.button.seeMore)+" "),_c('img',{attrs:{"src":_vm.imagePath,"alt":"Button View More Project"}})])])])],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }